<template>
  <div class="mt-4  px-5">

    <h2>Benutzer</h2>

    <v-data-table
        :headers="headers"
        :items="comp_Users"
        sort-by="nummer"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item.LastLogin="{ item }">
        <span>{{ new Date(item.LastLogin).toLocaleString() }}</span>
      </template>
      <template v-slot:item.email_verified_at="{ item }">
        <span>{{ new Date(item.email_verified_at).toLocaleString() }}</span>
      </template>

      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Users',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        align: 'Kurz',
        sortable: false,
      },
      {text: 'MitgliedsNR', value: 'Mitgliedsnummer'},
      {text: 'Vorname', value: 'vorname'},
      {text: 'Name', value: 'name'},
      {text: 'Ort', value: 'ort'},
      {text: 'PLZ', value: 'PLZ'},
      {text: 'Strasse', value: 'strasse'},
      {text: 'E-Mail', value: 'email'},
      {text: 'Verein', value: 'ClubName'},
      {text: 'Einträge Schießbuch', value: 'shootingbooks_count'},
      {text: 'letzter Login', value: 'LastLogin'},
      {text: 'Verified', value: 'email_verified_at'},
    ],

    editedItem: [],
  }),
  computed: {
    comp_Users: {
      get() {
        return this.$store.state.AdminApp.Users;
      }
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {

    getUsers() {
      this.$store.dispatch('AdminApp/getUsersData');
    },
    editItem(item) {
      console.log(item);
    },
    initialize() {
      this.getUsers();
    },
  },
}
</script>

<style scoped>

</style>